<template>
  <div class="itemview" @touchstart="touchstart">
    <div v-if="comefromBadSite" class="itemview-menu-not-avaliable">
      {{ $t('menuNotAvailable') }}
    </div>
    <template v-else>
      <PlacesSlideModal
        :startPoolAt="uid"
        @pickPlace="pickPlace"
        @drop="drop"
        @openInfo="openInfo"
        v-if="!menuMode && items.length"
        :delivery="delivery"
        :deepLink="deepLink"
      />
      <RestrauntMenu
        v-if="menuMode && currentMenuItem === 'LIST'"
        :categories="cleanCategories"
        :dishes="dishes"
        :key="uid"
        :currencySymbol="currencySymbol"
        :drinks="drinks"
        @close="menuMode = false"
        :drinkTitle="drinkTitle"
        :placeUid="placeUid"
        :isHotel="isHotel"
      />
      <TabTile
        v-else-if="menuMode && currentMenuItem === 'TILE'"
        :categories="cleanCategories"
        :dishes="dishes"
        :key="`${uid}-title`"
        :currencySymbol="currencySymbol"
        :drinks="drinks"
        @close="menuMode = false"
        :drinkTitle="drinkTitle"
        :placeUid="placeUid"
        :isHotel="isHotel"
        :hasIntegration="hasIntegration"
      />
      <SlideModal
        v-else-if="menuMode && showDrinks && currentMenuItem === 'SWIPE'"
        :key="`${uid}-drinks`"
        :categories-with-dishes="drinksWithDishes"
        :currencySymbol="currencySymbol"
        :drinks="true"
        @close="showDrinksFn"
        :isHotel="isHotel"
      />
      <SlideModal
        v-else-if="menuMode && currentMenuItem === 'SWIPE'"
        :key="`${uid}-swipe`"
        :categories-with-dishes="categoriesWithDishes"
        :currencySymbol="currencySymbol"
        :isHotel="isHotel"
      />
      <BestDishes
        v-else-if="menuMode && currentMenuItem === 'BEST'"
        :categories="cleanCategories"
        :dishes="bestDishes"
        :drinks="drinks"
        :key="`${uid}-best`"
        :currencySymbol="currencySymbol"
        @close="menuMode = false"
        :isHotel="isHotel"
      />
      <template v-if="menuMode">
        <div>
          <img
            width="30px"
            height="30px"
            :src="orderIcon"
            class="carousel-users-icon order-icon"
            @click="openOrder"
          />
          <div
            class="carousel-users-icon order-counter"
            v-if="orderLen"
            @click="openOrder"
          >
            {{ orderLen }}
          </div>
        </div>
      </template>
      <section id="carousel-menu">
        <img
          v-if="
            menuMode &&
            !showDrinks &&
            drinks &&
            drinks.length &&
            currentMenuItem === 'SWIPE'
          "
          :key="'drinks'"
          width="30px"
          src="/img/icon_swipe_drinks.svg"
          class="carousel-map-icon"
          @click="showDrinksFn"
        />
        <template v-if="!menuMode && !historyMode">
          <img
            :key="'map'"
            width="30px"
            height="30px"
            src="/img/iconMap.png"
            srcset="/img/iconMap@3x.png 3x /img/iconMap@2x.png 2x /img/iconMap.png 1x"
            class="carousel-map-icon"
            @click="drop(uid)"
          />
          <img
            :key="'users'"
            width="30px"
            height="30px"
            src="/img/iconChatsRequestsCopy7.svg"
            class="carousel-users-icon"
            @click="showMembersModal"
          />
        </template>
        <div class="itemview-menu" @click="clickMenuItem" v-if="menuMode">
          <hooper
            :infiniteScroll="true"
            :itemsToShow="2.7"
            :centerMode="true"
            ref="hooper"
            @slide="menuSlided"
            translate="no"
          >
            <slide v-for="(menuItem, i) in menuItems" :key="i" :data-index="i">
              {{ menuItem }}
            </slide>
          </hooper>
          <img src="/img/rectangle.png" />
        </div>
        <div class="itemview-menu" v-else @click="openMenu(false, true)">
          <img src="/img/rectangle.png" />
          <div class="itemview-menu__text">MENU</div>
        </div>
      </section>
      <!-- <div class="itemview-menu">
                <div class="itemview-menu__item" v-for="(menuItem, i) in menuItems" :key="i">
                    
                </div>
            </div> -->
      <img
        src="/img/backWhite.png"
        srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
        class="itemview-back"
        @click="$router.back()"
        v-if="historyMode"
        key="back"
      />
      <div class="itemview-topbuttons" v-else-if="!menuMode">
        <div class="itemview-topbuttons__button -left">
          <img
            src="/img/iconProfile.svg"
            @click="openLoginModal"
            key="profile"
          />
        </div>
        <div class="itemview-topbuttons__button">
          <div
            class="itemview-topbuttons__button-deliverybtn"
            @click="openMenu(true, true)"
            v-if="isDeliverySupported"
          >
            Delivery
          </div>
        </div>
        <div class="itemview-topbuttons__button -right">
          <img src="/img/smsKi.svg" @click="showMembersModal" key="messsages" />
          <!-- <img
                        src="/img/call-bell-white.svg"
                        @click="enterCode(placeUid)"
                    /> -->
        </div>
      </div>
      <div class="itemview-notify" v-show="addCartAlert">
        <img src="/img/line11@3x.png" />
        <div class="itemview-notify__text">{{ $t('added_to_order') }}</div>
      </div>
      <div class="add-to-order-help" v-show="isFirstOrder" id="swipe-up-helper">
        <img src="/img/double-up-chevrone.png" />
        <div class="add-to-order-help__text">
          {{ $t('add_to_order_help') }}
        </div>
      </div>
    </template>
    <OrderTutorial v-if="!hasIntegration && isShowedTutorial" @close="openCloseTutorial(false)" />
  </div>
</template>
<script>
import RestrauntMenu from '@/components/RestrauntMenu'
import TabTile from '@/components/TabTile'
import SwipeModal from '@/components/SwipeModal'
import SlideModal from '@/components/SlideModal'
import PlacesSlideModal from '@/components/PlacesSlideModal'
import BestDishes from '@/components/BestDishes'
import VueSwing from 'vue-swing'
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import callWaiterMixin from '@/mixins/callWaiterMixin'
import { currentApplicationUrl, checkIntegrationInPlace } from '@/utils'
import OrderTutorial from '@/components/Tutorials/OrderTutorial/OrderTutorial'
import { CREATE_ORDER_HELPER_DELAY } from '@/consts'

const currencies = {
  RUB: '₽',
  EUR: '€',
  USD: '$',
  TZS: 'S',
  KZT: '₸',
  BYN: 'BYN',
  AED: 'د.إ',
  GEL: '₾',
}
const unsubscribes = {}

const drinksTitles = {
  rus: 'Напитки',
  eng: 'Drinks',
  ara: 'المشروبات',
  chi: '飲料',
  zho: '飲料',
  fra: 'Les boissons',
  fre: 'Les boissons',
  geo: 'სასმელები',
  ger: 'Die Getränke',
  tur: 'İçecekler',
}

const PLACES_NO_MENU_FORM_DEEP_LINKS = {
  // sansar : prod
  '2d77bf27-e9b2-4fb4-a4b2-25b28dd9823b': true,
  // sergey rest : stage
  'b409c246-6b36-4745-99ea-159944e1f173': true,
}

export default {
  name: 'ItemView',
  mixins: [callWaiterMixin],
  props: {
    placeUid: {
      type: String,
      default: '',
    },
    deepLink: {
      type: Boolean,
      default: false,
    },
    menuModeQuery: {
      type: Boolean,
      default: false,
    },
    menuLangQuery: {
      type: String,
      default: '',
    },
  },
  provide: function () {
    const appData = {}

    Object.defineProperty(appData, 'rootPlaceUid', {
      enumerable: true,
      get: () => this.uid,
    })
    Object.defineProperty(appData, 'rootPlaceType', {
      enumerable: true,
      get: () => this.currentItem?.type,
    })

    return appData
  },
  components: {
    Hooper,
    Slide,
    RestrauntMenu,
    TabTile,
    SwipeModal,
    SlideModal,
    PlacesSlideModal,
    BestDishes,
    OrderTutorial,
  },
  data() {
    const { placeUid, $PS } = this
    const items = $PS.data
    let visibleIndex = items.findIndex((item) => item.uid === placeUid)
    if (!~visibleIndex) {
      visibleIndex = 0
    }
    const ordersStore = window.ordersStore
    const comefrom = sessionStorage.getItem('comefrom') ?? ''
    return {
      order: ordersStore.orderItems,
      addCartAlert: false,
      timerAlert: null,
      visualItems: [],
      allDishes: [],
      categories: [],
      allDrinks: [],
      visibleIndex,
      busy: false,
      loadingMenu: false,
      currentMenuItem: 'TILE',
      lastEl: null,
      reset: false,
      uid: placeUid,
      showDrinks: false,
      menuMode: this.menuModeQuery,
      unsubscribe: null,
      menuLang: '',
      items,
      isDishInfoOpened: false,
      orderOpening: false,
      openedFromRestPicker: false,
      openedFromRestPickerDelivery: false,
      comefromBadSite: !!~comefrom.indexOf('kleek.ru'),
      isShowedTutorial: false,
    }
  },
  computed: {
    placeType() {
      return this.currentItem?.type
    },
    categoriesWithDishes() {
      const { dishes, categories, currentCheckin } = this
      return categories.reduce((memo, category) => {
        const c_uid = category.uid
        if (!currentCheckin && category.for_checked_in_users_only) return memo
        const _dishes = dishes.filter((dish) => dish.category_uid === c_uid)
        if (_dishes && _dishes.length) {
          return [
            ...memo,
            {
              ...category,
              dishes: _dishes,
            },
          ]
        }
        return memo
      }, [])
    },
    drinksWithDishes() {
      const { dishes, allDrinks, currentCheckin } = this
      return allDrinks.reduce((memo, category) => {
        const c_uid = category.uid
        if (!currentCheckin && category.for_checked_in_users_only) return memo
        const _dishes = dishes.filter((dish) => dish.category_uid === c_uid)
        if (_dishes && _dishes.length) {
          return [
            ...memo,
            {
              ...category,
              dishes: _dishes,
            },
          ]
        }
        return memo
      }, [])
    },
    initByDeep() {
      return this.placeUid && this.deepLink
    },
    currentCheckin() {
      return this.$checkins.find((ch) => ch.place_uid === this.placeUid)
    },
    drinkTitle() {
      const menuLang = this.menuLang || 'en'
      const byLang = drinksTitles[menuLang] || drinksTitles.en
      return byLang
    },
    drinks() {
      const { dishes, allDrinks, currentCheckin } = this
      return allDrinks.filter((drinkCategory) => {
        if (!currentCheckin && drinkCategory.for_checked_in_users_only)
          return false
        const d_c_uid = drinkCategory.uid
        return dishes.find((dish) => dish.category_uid === d_c_uid)
      })
    },
    orderLen() {
      return this.order.length
    },
    isFirstOrder() {
      if (this.isDishInfoOpened || !this.menuMode) {
        return false
      }

      const isFirstOrderFlag = sessionStorage.getItem('isFirstOrder')

      if (isFirstOrderFlag === null) {
        if (this.currentMenuItem === 'SWIPE' && this.orderLen === 0) {
          return true
        }
        return false
      } else {
        return false
      }
    },
    delivery: {
      get() {
        return this.$store.state.user.delivery
      },
      set(val) {
        this.$store.commit('user/SET_DELIVERY', val)
      },
    },
    historyMode() {
      return this.$route.query.history
    },
    visualItemsLen() {
      return this.visualItemsComputed.length
    },
    visualItemsComputed() {
      const { items, visibleIndex } = this

      return items.slice(visibleIndex, visibleIndex + 2)
    },
    currentItem() {
      const { items, uid } = this
      return items.find((item) => item.uid === uid)
    },
    isHotel() {
      return this.currentItem?.type === 'hotel'
    },
    languages() {
      return this.currentItem.languages
    },
    isDeliverySupported() {
      // return false
      const currentItem = this.currentItem
      return currentItem?.supported_flows?.delivery
    },
    // uid() {
    // 	return this.currentItem.uid
    // },
    currencySymbol() {
      const currency = this.currentItem?.currency || 'USD'
      return currencies[currency]
    },
    dishes() {
      const { delivery, allDishes } = this
      if (delivery) {
        return allDishes.filter((dish) => !dish.is_hidden && dish.is_delivering)
      }
      return allDishes.filter((dish) => !dish.is_hidden)
    },
    categoryUids() {
      return new Set(this.dishes.map((d) => d.category_uid))
    },
    cleanCategories() {
      const { categoryUids, categories } = this
      return categories.filter((cat) => categoryUids.has(cat.uid))
    },
    user() {
      return this.$store.state.user.user
    },
    bestDishes() {
      return this.dishes.filter((d) => d.is_the_best_dish)
    },
    menuItems() {
      const currentItem = this.currentItem || {}
      const {
        is_list_menu_available,
        is_list_menu_hidden,
        is_swipe_menu_available_new,
        is_tile_menu_available_new,
      } = currentItem
      const $_out = []
      if (is_tile_menu_available_new) {
        $_out.push('TILE')
      }
      if (is_swipe_menu_available_new) {
        $_out.push('SWIPE')
      }
      if (is_list_menu_available && !is_list_menu_hidden) {
        $_out.push('LIST')
      }
      if (this.bestDishes.length) {
        $_out.push('BEST')
      }
      return $_out
    },
    orderIcon() {
      return this.orderLen || this.$checkins.length
        ? `/img/iconCheck@3x.png`
        : `/img/iconCheckEmpty@3x.png`
    },
    hasIntegration() {
      return checkIntegrationInPlace(this.currentItem)
    }
  },
  created() {
    // this.$store.dispatch('places/showLoader')
    this.initSlider()
    this.$bus.$on('openInfo', this.openInfo)
    const query = this.$route.query
    if (query.category) {
      this.loadMenu(this.uid, !!query.delivery, !!query.takeAway)
    } else if (query.openRelated) {
      this.openMenu()
    }
    this.$bus.$on('closeMenuMode', this.closeMenuMode)
    this.$bus.$on('alertShow', this.alertShow)
    this.$bus.$on('loadMenu', this.loadMenu)
    if (!sessionStorage.getItem('tutorialClosed')) {
      this.$bus.$on('openOrderTutorial', this.openCloseTutorial)
    }
  },
  watch: {
    // uid: {
    // 	handler: 'loadMenu',
    // 	immediate: true
    // },
    uid() {
      try {
        this.$root.langModal.closeMe()
      } catch (e) {
        console.log(e)
      }
    },
    menuMode(val) {
      if (!val) {
        this.delivery = false
        this.openInfo(val)
        const subsUids = Object.keys(unsubscribes)
        if (subsUids.length) {
          subsUids.forEach((uid) => {
            const uidFunc = unsubscribes[uid]
            if (typeof uidFunc === 'function') {
              uidFunc()
              delete unsubscribes[uid]
            }
          })
        }
        if (this.openedFromRestPicker) {
          this.openMenu(this.openedFromRestPickerDelivery)
          this.openedFromRestPicker = false
          this.openedFromRestPickerDelivery = false
        }
      }
    },
    initByDeep: {
      immediate: true,
      handler: async function (val) {
        if (val) {
          await this.$PS.initOnePlace(this.placeUid)
          if (this.deepLink) {

            this.openMenu()
          }
        }
      },
    },
  },
  beforeDestroy() {
    clearTimeout(this.createOrderHelperTimer)
    this.$root.langModal.closeMe()
  },
  methods: {
    openCloseTutorial(value) {
      if (!value) {
        this.isShowedTutorial = false
      } else if (!this.createOrderHelperTimer) {
        this.createOrderHelperTimer = setTimeout(() => {
          this.isShowedTutorial = value
        }, CREATE_ORDER_HELPER_DELAY)
      }
    },
    alertShow() {
      clearTimeout(this.timerAlert)
      this.addCartAlert = true
      this.timerAlert = setTimeout(() => {
        this.addCartAlert = false
      }, 1000)
    },
    openOrder() {
      if (this.orderOpening) return
      this.orderOpening = true
      this.$router
        .replace(`/orders?openFromUid=${this.placeUid}`)
        .then(() => {
          this.orderOpening = false
        })
        .catch(() => {
          this.orderOpening = false
        })
    },
    showMembersModal() {
      const actions = [
        {
          id: 'ok',
          text: this.$t('cancel'),
        },
      ]
      if (currentApplicationUrl) {
        actions.unshift({
          id: 'download',
          text: this.$t('download'),
          url: currentApplicationUrl,
        })
      }
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('full_functioal_modal.header'),
          subtitle: this.$t('full_functioal_modal.text'),
          icon: '/img/infoWhite.svg',
        },
        actions,
        resolve: () => ({}),
        reject: () => ({}),
      })
    },
    openLoginModal() {
      const user = this.user
      if (user && user.uid && !user.isAnonymous) {
        this.$router.push(`/user/${user.uid}?openInfo=true`)
      } else {
        this.$bus.$emit('openCloseAuth', { open: true })
      }
    },
    closeMenuMode() {
      this.menuMode = false
    },
    prePickAddress(currentItem) {
      return new Promise((resolve) => {
        this.$bus.$off('PreAddressClose')
        this.$bus.$on('PreAddressClose', (succes) => {
          this.$bus.$off('PreAddressClose')
          resolve(succes)
        })
        this.$bus.$emit('PreAddressOpen', currentItem)
      })
    },
    pickAddress(currentItem) {
      return new Promise((resolve) => {
        this.$bus.$off('AddresPickerClose')
        this.$bus.$on('AddresPickerClose', (succes) => {
          this.$bus.$off('AddresPickerClose')
          resolve(succes)
        })
        this.$bus.$emit('AddresPickerOpen', currentItem)
      })
    },
    openLangs: async function (delivery = false, userClick = false) {
      const currentItem = this.currentItem
      const uid = currentItem.uid
      const languages = currentItem?.languages || []
      // if (delivery) {
      //     const prePick = await this.prePickAddress(currentItem)
      // 	if (!prePick) return
      // 	const takeAway = prePick.takeAway
      // 	if (prePick.success && !takeAway) {
      // 		const selectedAddress = await this.pickAddress(currentItem)
      // 		if (!selectedAddress) return
      // 	}
      //     this.loadMenu(this.uid, delivery, takeAway)
      // } else {
      let lang = ''

      if (languages && languages.length > 1) {
        this.$store.dispatch('places/hideLoader')
        const menuLangQuery = this.menuLangQuery
        if (menuLangQuery) {
          lang = menuLangQuery
        } else {
          try {
            lang = await this.$root.langModal.show(languages)
          } catch (e) {
            console.log(e)
          }
        }
        if (lang) {
          localStorage.setItem('langKey:' + uid, lang)
          // this.loadMenu(this.uid, delivery)
          if (this.deepLink) {
            const placeUrl =
              this.$route.path + '?menumode=true&menulang=' + lang
            this.$router.replace(placeUrl).catch(() => {})
            sessionStorage.setItem('backToPlaceUrl', placeUrl)
            this.loadMenu(this.uid, delivery, false)
          } else {
            this.$bus.$emit('translatePlace', uid, lang, delivery)
          }
        }
      } else {
        // кастомное поведение для отдельных ресторанов показывать не меню сразу при переходе по диплинке
        if (PLACES_NO_MENU_FORM_DEEP_LINKS[uid] && this.deepLink) {
          if (userClick) {
            this.loadMenu(this.uid, delivery)
          } else {
            this.$router.replace(this.$route.path).catch(() => {})
            this.$store.dispatch('places/hideLoader')
          }
        } else {
          if (this.deepLink) {
            this.$router
              .replace(this.$route.path + '?menumode=true')
              .catch(() => {})
          }
          this.loadMenu(this.uid, delivery)
        }
      }
      // }
    },
    openMenu: async function (delivery = false, userClick = false) {
      const related_places_uids =
        this.currentItem?.related_places_uids?.slice() || []
      if (!delivery && related_places_uids.length) {
        if (this.currentItem.has_menu) {
          related_places_uids.unshift(this.currentItem.uid)
        }
        this.$root.chooseRests.show(
          related_places_uids,
          this.currentItem?.uid,
          this.currentItem?.type,
          (lang, uid) => {
            this.openedFromRestPicker = true
            this.openedFromRestPickerDelivery = delivery
            localStorage.setItem('langKey:' + uid, lang)
            this.loadMenu(uid, delivery, false, lang)
          },
          this.hasIntegration
        )
      } else {
        const deepLinkQuery = JSON.parse(
          localStorage.getItem('deepLinkQuery') || '{}'
        )
        if (deepLinkQuery.menu_item && deepLinkQuery.language) {
          localStorage.setItem(
            'langKey:' + deepLinkQuery.place,
            deepLinkQuery.language
          )
          this.loadMenu(
            deepLinkQuery.place,
            false,
            false,
            deepLinkQuery.language
          )
        } else {
          this.openLangs(delivery, userClick)
        }
      }
    },
    showDrinksFn() {
      this.showDrinks = !this.showDrinks
      this.openInfo(this.showDrinks)
    },
    openInfo(val) {
      this.isDishInfoOpened = val
      const menu = document.getElementById('carousel-menu')
      if (menu) {
        menu.style.display = val ? 'none' : 'block'
      }
      if (!val || this.currentMenuItem === 'SWIPE') {
        const hideOnSwipe = document.getElementById('hide-on-swipe')
        if (hideOnSwipe) {
          hideOnSwipe.style.display = val ? 'none' : 'block'
        }
      }
    },
    drop(uid) {
      if (this.deepLink) {
        sessionStorage.removeItem('backToPlaceUrl')
        location.href = '/'
      } else {
        this.$emit('drop', uid)
      }
    },
    pickPlace(uid) {
      this.uid = uid
    },
    clickMenuItem(e) {
      let index = e.target?.dataset?.index || 0
      index = Number(index)
      this.$refs.hooper.slideTo(index)
      window.requestAnimationFrame(() => {
        this.currentMenuItem = this.menuItems[index]
      })
    },
    menuSlided(ev) {
      this.$nextTick(() => {
        const item = document.querySelector(
          '.itemview-menu .hooper-slide.is-active.is-current'
        )
        const currentSlide = item ? Number(item.dataset.index) : 0
        window.requestAnimationFrame(() => {
          this.currentMenuItem = this.menuItems[currentSlide]
        })
      })
    },
    onMountedMenu(splide) {
      // do something
    },
    dragstart({ target, throwDirection }) {
      if (!this.visibleIndex) return
      // if (this.busy) return
      if (throwDirection === VueSwing.Direction.RIGHT) {
        this.reset = true
        this.$refs.swing.stack.getCard(target).throwIn(0, 0)
        window.requestAnimationFrame(() => {
          this.visibleIndex--
        })
        this.busy = true
      }
    },
    dragend() {
      this.reset = false
      // this.busy = false
    },
    initSlider() {
      const { items, placeUid } = this
      const currentIndex = this.items.findIndex((item) => item.uid === placeUid)
      let toSlider = []
      if (~currentIndex) {
        toSlider = [...toSlider, items[currentIndex]]
        const next = items[currentIndex + 1]
        if (next) {
          toSlider = [...toSlider, next]
          this.visibleIndex = currentIndex
        }
        this.visualItems = toSlider
      }
    },
    tStart(e) {
      // console.log('tStart', e)
    },
    tMove(e) {
      // console.log('tMove', e)
    },
    throwout({ target, throwDirection }) {
      const uid = target.dataset.id
      const direction = throwDirection.toString()
      if (direction === 'Symbol(LEFT)') {
        target.style.cssText = ''
        this.visibleIndex++
      } else if (direction === 'Symbol(RIGHT)') {
        this.$refs.swing.stack.getCard(target).throwIn(-550, 0)
      } else if (direction === 'Symbol(DOWN)') {
        this.$emit('drop', uid)
      }
    },
    throwoutend({ target, direction }) {
      target.style.transform =
        'translate3d(0, 0, 0) translate(-500px, 0px) rotate(0deg)'
    },
    onThrowin(e) {
      // console.log(`Threw in!`, e)
    },
    throwoutleft(e) {
      // console.log(`throwoutleft!`, e)
    },
    doSomething(e) {
      // console.log(e)
    },
    updateItems(updated = []) {
      const items = this.allDishes
      updated.forEach((update) => {
        const item = items.find((i) => i.uid === update.uid)
        if (item) {
          Object.keys(item).forEach((key) => {
            const updateVal = update[key]
            if (item[key] !== updateVal) {
              item[key] = updateVal
            }
          })
        }
      })
    },
    subscribeOnItems(category_uids) {
      return new Promise((resolve) => {
        unsubscribes[category_uids.join('-')] = this.$DB.db
          .collection('menu_items')
          .where('category_uid', 'in', category_uids)
          .onSnapshot((snapshot) => {
            const [newItems, updates, removed] = [[], [], []]

            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                newItems.push(change.doc.data())
              }
              if (change.type === 'modified') {
                updates.push(change.doc.data())
              }
              if (change.type === 'removed') {
                removed.push(change.doc.data())
              }
            })
            if (updates.length) {
              this.updateItems(updates)
            }
            if (removed.length) {
              this.removeItems(removed)
            }
            if (newItems.length) {
              resolve(newItems)
            } else {
              resolve([])
            }
          })
      })
    },
    subscribeOnAllItemsByPlaceUid(uid) {
      unsubscribes[uid] = this.$DB.db
        .collection('menu_items')
        .where('place_uid', '==', uid)
        .onSnapshot((snapshot) => {
          const [updates, removed] = [[], []]

          snapshot.docChanges().forEach((change) => {
            if (change.type === 'modified') {
              updates.push(change.doc.data())
            }
            if (change.type === 'removed') {
              removed.push(change.doc.data())
            }
          })
          if (updates.length) {
            this.updateItems(updates)
          }
          if (removed.length) {
            this.removeItems(removed)
          }
        })
    },
    async getMenuItems(place_uid, language = '') {
      let items = []
      const postData = { place_uid }

      if (language) {
        postData.language = language
      }

      try {
        const resp = await this.$axios.post(
          this.$API_URL('/place/menu/getItems'),
          postData
        )
        items = resp?.data?.payload?.items || []
      } catch (err) {
        const eStatus = err?.response?.status
        if (eStatus === 401) {
          items = await this.$bus.updateToken(
            this.getMenuItems.bind(this),
            place_uid,
            language
          )
        }
      }
      return items
    },
    openEnterTableModal(uid) {
      const placeUidFromDeepLink = localStorage.getItem('fromDeepLink')
      if (!placeUidFromDeepLink) {
        return
      } else {
        window.ordersStore.setDevice(placeUidFromDeepLink)
      }

      localStorage.removeItem('fromDeepLink')
      if (this.currentItem?.order_processing_type === false) {
        this.enterCode(uid)
      }
    },
    loadMenu(uid, delivery = false, takeAway = false, forceLang = '') {
      if (this.loadingMenu) return
      this.$store.dispatch('places/showLoader')
      window.ordersStore.changeDeliveryFlow(delivery, takeAway)
      this.delivery = delivery
      this.loadingMenu = true
      const languages = this.languages
      const userCacheLang = localStorage.getItem('langKey:' + uid)
      const userIso3 = this.$iso3Lang
      let promiseCategories = null
      let lang = ''
      
      this.openEnterTableModal(uid)
      if (forceLang || (languages && languages.length > 1)) {
        lang =
          forceLang || ~languages.indexOf(userCacheLang)
            ? userCacheLang
            : ~languages.indexOf(userIso3)
            ? userIso3 || languages[0]
            : languages[0]
        this.menuLang = forceLang || lang
        promiseCategories = this.$DB.db
          .collection('menu_categories')
          .where('place_uid', 'in', [uid])
          .where('language', '==', this.menuLang)
        localStorage.setItem('langKey:' + uid, '')
      } else {
        this.menuLang = this.$iso3Lang
        promiseCategories = this.$DB.db
          .collection('menu_categories')
          .where('place_uid', 'in', [uid])
      }
      Promise.all([promiseCategories.get()])
        .then(async (resps) => {
          const items = await this.getMenuItems(uid, lang)
          this.subscribeOnAllItemsByPlaceUid(uid)
          return [resps[0].docs.map((doc) => doc.data()), items]
        })
        .then((resps) => {
          const [categories, items] = [
            resps[0],
            resps[1].map((doc) => {
              let _search = (doc.title || '').toLowerCase()
              if (~_search.indexOf('ё')) {
                _search += ' ' + _search.replace(/ё/g, 'е')
              }
              if (~_search.indexOf('е')) {
                _search += ' ' + _search.replace(/е/g, 'ё')
              }
              return {
                ...doc,
                _search,
              }
            }),
          ]
          items.sort((a, b) => {
            const [aSort, bSort] = [a.sort_index, b.sort_index]
            if (aSort > bSort) {
              return 1
            } else if (aSort < bSort) {
              return -1
            }
            return 0
          })
          const imageRegexp = new RegExp(/\.png|\.jpg|\.jpeg/)
          let drinks = []
          for (let i = categories.length - 1; i >= 0; i--) {
            const cat = categories[i]
            let photo_uri = cat.photo_uri
            const uid = cat.uid
            if (!photo_uri || !imageRegexp.test(photo_uri)) {
              // find image to category
              const _item =
                items.find(
                  (i) =>
                    i.category_uid === uid &&
                    (delivery ? i.is_delivering : true) &&
                    i.is_active &&
                    !i.is_hidden
                ) || {}
              const _uri = _item.photo_uri || _item.comment_photo_uri
              if (_uri) {
                photo_uri =
                  _uri ===
                  'https://storage.googleapis.com/mayber-prod.appspot.com/'
                    ? '/img/restaurantPlaceholder.jpg'
                    : _uri
              } else {
                photo_uri = '/img/restaurantPlaceholder.jpg'
              }
              cat.photo_uri = photo_uri
            }
            Object.freeze(cat)
            if (cat.type === 'drink') {
              const drinkCat = categories.splice(i, 1)[0]
              drinks.push({
                ...drinkCat,
                category_uid: '1',
              })
            }
          }
          categories.sort((a, b) => {
            const [aSort, bSort] = [a.sort_index, b.sort_index]
            if (aSort > bSort) {
              return 1
            } else if (aSort < bSort) {
              return -1
            }
            return 0
          })
          drinks.sort((a, b) => {
            const [aSort, bSort] = [a.sort_index, b.sort_index]
            if (aSort > bSort) {
              return 1
            } else if (aSort < bSort) {
              return -1
            }
            return 0
          })

          this.categories = categories

          this.allDishes = items
          this.allDrinks = drinks
          this.$nextTick(() => {
            this.$store.dispatch('places/hideLoader')
            this.loadingMenu = false
            this.menuMode = true
            if (this.$route.query.category) {
              const { hooper } = this.$refs
              if (hooper) {
                hooper.slideTo(1)
              }
              setTimeout(() => {
                if (this.$route.path !== '/orders') {
                  if (!this.$route.query.history) {
                    this.$router.replace('/')
                  }
                }
              }, 1000)
            }
          })
        })
        .catch((e) => {
          console.log(e)
          this.loadingMenu = false
        })
    },
    touchstart(ev) {
      // console.log('touchstart',ev)
      // ev.preventDefault()
      // ev.stopPropagation()
      // this.$refs['swing'].cards[0].throwIn(0, 0)
    },
  },
}
</script>
<style lang="scss">
.itemview {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  overflow: hidden;

  &-menu-not-avaliable {
    @include flex(row, center, center);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: $black;
    padding: 0 25px;
    color: $white;
    @include font(20, 24, bold);
  }

  &-notify {
    @include pos-centered;
    position: fixed !important;
    z-index: 100;
    height: 122px;
    width: calc(100% - 120px);
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    @include flex(column, center, center);

    &__text {
      @include font(24, 24, bold);
      color: $white;
    }

    img {
      width: 48px;
      margin-bottom: 17px;
    }
  }

  &-topbuttons {
    position: fixed;
    z-index: 102;
    top: 32px;
    left: 29px;
    right: 29px;
    display: flex !important;
    @include flex(row, flex-start, center);

    &__button {
      user-select: none;
      width: 33.33%;
      height: 100%;
      @include flex(row, flex-start, center);

      &.-right {
        @include flex(row, flex-end, center);
      }

      &-deliverybtn {
        width: 106px;
        height: 40px;
        border: 1.5px solid $white;
        border-radius: 20px;
        @include font(17, 32, 600);
        @include flex(row, center, center);
        text-transform: uppercase;
        color: $black;
        background: $white;
      }
    }
  }

  &-user {
    position: fixed;
    z-index: 102;
    top: 29px;
    left: 29px;
  }

  &-back {
    position: fixed;
    z-index: 102;
    top: 29px;
    left: 29px;
    width: 30px;
    height: 30px;
  }

  &-toplace {
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 99;
  }

  .carousel-map-icon {
    position: fixed !important;
    bottom: 45px;
    left: 16px;
    z-index: 99;
  }

  .carousel-users-icon {
    position: fixed !important;
    bottom: 40px;
    right: 8px;
    z-index: 99;
    width: 42px;
    height: 42px;

    &.order-icon {
      width: 30px;
      height: 30px;
      bottom: 45px;
      right: 15px;
    }

    &.order-counter {
      width: 10px;
      height: 10px;
      user-select: none;
      background: red;
      @include font(10, 10);
      color: #ffffff;
      @include flex(row, center, center);
      padding: 5px;
      border-radius: 50%;
      bottom: 60px;
      right: 10px;
    }
  }

  &-menu {
    position: fixed !important;
    bottom: 20px;
    width: 70%;
    height: 50px;
    z-index: 99;
    padding: 5px 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      @include pos-centered-h;
      top: -8px;
      z-index: -1;
      max-width: 120px;
    }

    &__text {
      @include pos-centered-h;
      top: 10px;
      @include font(20, 20, 500);
      color: #fff;
    }

    .hooper {
      height: 30px;
      @include font(20, 20, 500);
      color: #fff;
      &:focus {
        outline: none;
      }

      &-list {
        box-shadow: inset 22px 0px 37px -20px #333;
        border-radius: 15px;
        background: rgba(184, 161, 94, 0.8);
        &:focus {
          outline: none;
        }
        // overflow: auto;
      }
      &-track {
        &:focus {
          outline: none;
        }
      }
      &-slide {
        @include flex(row, center, center);

        &.is-active.is-current {
          position: relative;

          &:before {
            // content: url('/img/rectangle.png');
            content: '';
            background-image: url('/img/rectangle.png');
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            width: 120px;
            height: 60px;
            z-index: -1;
          }
        }
      }
    }
  }

  &-items {
    width: 100%;
    position: relative;
    touch-action: none !important;

    > div:first-child {
      touch-action: none !important;
    }

    &.-reset {
      pointer-events: none;
    }

    &__item {
      position: absolute;
      top: 0;
      width: 100%;

      &-label {
        @include flex(column-reverse, flex-start, fllex-start);
        position: absolute;
        left: 15px;
        right: 16px;
        padding-right: 64px;
        bottom: 130px;
        z-index: 1;
        color: #fff;
        text-align: left;
        text-shadow: 1px 1px #000;
        transition: top 0.15s linear;
        will-change: top;

        .l-item-name {
          @include font(32, 32, bold);
        }

        .l-item-description {
          @include font(15, 15);
          margin-top: 8px;
        }

        .l-item-distance {
          @include font(15, 15);
          margin-top: 8px;
        }

        .l-item-icon {
          position: fixed;
          z-index: 102;
          right: 10px;
          bottom: 150px;
        }

        .l-item-reserve {
          width: 100%;
          margin-left: 32px;
          margin-bottom: 40px;
          @include flex(row, center, center);

          div {
            width: 106px;
            height: 40px;
            @include flex(row, center, center);
            @include font(17, 21, 400);
            text-transform: uppercase;
            background: rgba(28, 28, 30, 0.95);
            border: 1.5px solid #ffffff;
            backdrop-filter: blur(13.5px);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 24px;
          }
        }
      }
    }
  }
}

.add-to-order-help {
  @include pos-centered;
  position: fixed !important;
  z-index: 100;
  padding: 15px 10px 25px;
  width: 280px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  @include flex(column, center, center);
  pointer-events: none;

  img {
    width: 120px;
    height: 60px;
    opacity: 0.7;
  }

  &__text {
    margin-top: 10px;
    @include font(20, 22, bold);
    text-transform: uppercase;
    color: $white;
  }
}
</style>
